<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.lastMinuteBookings') }}</h3>
    <div>
      <select class="flix-form-control flix-form-group" v-model="selected.lastminute" @change="callback(selected.lastminute)">
        <option v-for="(value, index) in lastminute" v-show="index" :key="index" :value="index">{{ value }}</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: { data: Object, callback: Function },
  data () {
    var selected = 0

    if (typeof this.data.exclude.lastminute !== 'undefined') {
      selected = this.data.exclude.lastminute * 1
    }

    return {
      selected: {
        lastminute: selected
      },
      lastminute: this.$t('demo.lastMinute')
    }
  },
  watch: {
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
